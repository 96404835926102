import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { AdminLayoutSidebarCompactComponent } from './shared/components/layouts/admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';
import { RoleGuard } from './shared/services/role.guard';

const adminRoutes: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGaurd, RoleGuard],
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
  },

  {
    path: 'customers',
    canActivate: [AuthGaurd, RoleGuard],
    loadChildren: () => import('./views/customers/customer.module').then(m => m.CustomerModule)
  },
  {
    path: 'developers',
    canActivate: [AuthGaurd, RoleGuard],
    loadChildren: () => import('./views/developers/developer.module').then(m => m.DeveloperModule)
  },

  {
    path: 'login ',
    loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
  },
  {
    path: 'projects/view/:project/apartments',
    canActivate: [AuthGaurd, RoleGuard],
    loadChildren: () => import('./views/apartments/appartment.module').then(m => m.AppartmentModule)
  },

  {
    path: 'doors',
    canActivate: [AuthGaurd, RoleGuard],
    loadChildren: () => import('./views/doors/door.module').then(m => m.DoorModule)
  },

  {
    path: 'suppliers',
    canActivate: [AuthGaurd, RoleGuard],
    loadChildren: () => import('./views/suppliers/suppliers.module').then(m => m.SuppliersModule)
  },

  {
    path: 'employees',
    canActivate: [AuthGaurd, RoleGuard],
    loadChildren: () => import('./views/employees/employee.module').then(m => m.EmployeeModule)
  },

  {
    path: 'extra-categories',
    canActivate: [AuthGaurd, RoleGuard],
    loadChildren: () => import('./views/extra-categories/extra-category.module').then(m => m.ExtraCategoryModule)
  },

  {
    path: 'settings',
    canActivate: [AuthGaurd, RoleGuard],
    loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule)
  },

  {
    path: 'invoice',
    canActivate: [AuthGaurd, RoleGuard],
    loadChildren: () => import('./views/invoice/invoice.module').then(m => m.InvoiceModule)
  },

  {
    path: 'orders',
    canActivate: [AuthGaurd, RoleGuard],
    loadChildren: () => import('./views/orders/order.module').then(m => m.OrderModule)
  },

  {
    path: 'installations',
    canActivate: [AuthGaurd, RoleGuard],
    loadChildren: () => import('./views/installation/installation.module').then(m => m.InstallationModule)
  },

  {
    path: 'projects',
    canActivate: [AuthGaurd, RoleGuard],
    loadChildren: () => import('./views/projects/projects.module').then(m => m.ProjectModule)
  },

  {
    path: 'reports',
    canActivate: [AuthGaurd, RoleGuard],
    loadChildren: () => import('./views/reports/report.module').then(m => m.ReportModule)
  },

  {
    path: 'project-doors',
    canActivate: [AuthGaurd, RoleGuard],
    loadChildren: () => import('./views/project-doors/project-door.module').then(m => m.ProjectDoorModule)
  },

  {
    path: 'door-extras',
    canActivate: [AuthGaurd, RoleGuard],
    loadChildren: () => import('./views/door-extras/door-extra.module').then(m => m.DoorExtraModule)
  },

  {
    path: 'door-types',
    canActivate: [AuthGaurd, RoleGuard],
    loadChildren: () => import('./views/door-types/door-type.module').then(m => m.DoorTypeModule)
  },

  {
    path: 'icons',
    loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
  },
];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: './views/sessions/sessions.module#SessionsModule'
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutSidebarCompactComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
 
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [AuthGaurd, RoleGuard]
})
export class AppRoutingModule { }
