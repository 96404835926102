import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { SearchService } from 'src/app/shared/services/search.service';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { Router, RouteConfigLoadStart, ResolveStart, RouteConfigLoadEnd, ResolveEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-admin-layout-sidebar-compact',
  templateUrl: './admin-layout-sidebar-compact.component.html',
  styleUrls: ['./admin-layout-sidebar-compact.component.scss']
})
export class AdminLayoutSidebarCompactComponent implements OnInit {
    moduleLoading: boolean;
  
    constructor(
      public navService: NavigationService,
      public searchService: SearchService,
      private router: Router,
      private route : ActivatedRoute,
      private userService : EmployeeService,
      private authService : AuthService
    ) { }
  
    ngOnInit() {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart){
          
          if(this.navService.confirmBeforeChange){
            // if(!confirm("Are you sure?")){
            //   return;
            //   }	
          }
        }
        if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
          this.moduleLoading = true;
        }
        if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
          this.moduleLoading = false;
        }
      });
    }

    setDefaultItem(){
      if(localStorage.getItem("menuItem")){
        this.navService.lastMenu = JSON.parse(localStorage.getItem("menuItem"));
      }
      if(this.navService.lastMenu){
        console.log("TRIGGERED!");
        this.navService.selectedItem = this.navService.lastMenu;
        this.setActiveMainItem(this.navService.lastMenu);
      }
    }

    setActiveMainItem(item) {
      this.navService.nav.forEach(item => {
        item.active = false;
      });
      item.active = true;
    }

}
