import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  private _jsonURL = 'assets/version.json';
  warned: boolean = false;
  closed: boolean = false;
  versionNumber = "";

  constructor(private http: HttpClient) {
    let _this = this;
    this.getJSON().subscribe(data => {
      environment.version = data['version'];
      this.versionNumber = environment.version;
    });
  }

  public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }

  ngOnInit() {
    let _this = this;
    let loadedVersion = environment.version;
    this.runCheck();

    setInterval(() => {
      loadedVersion = environment.version;
      _this.runCheck();
    }, 1000 * 60 * 60);
  }
  
  runCheck(){
    let _this = this;
    this.getJSON().subscribe(data => {
      let latestVersion = data['version'];
      if(!_this.warned && latestVersion != environment.version){
        _this.warned = true;
      }
    });
  }

  updatePage(){
    location.reload();
  }

}
