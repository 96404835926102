import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RolesService } from './roles.service';
import { AuthService } from './auth.service';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    constructor(
        private rolesService : RolesService,
        private authService : AuthService
    ) {
    }

    selectedItem: IMenuItem;
    nav: IMenuItem[];
    confirmBeforeChange = true;
    lastMenu: any;

    defaultMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            type: 'link',
            disabled: this.canShow('dashboard'),
            icon: 'i-Bar-Chart',
            state: '/dashboard'
        },
        {
            name: 'Orders',
            disabled: this.canShow('orders'),
            type: 'dropDown',
            icon: 'i-Receipt',
            sub: [
                //{ icon: 'fal fa-plus', name: 'Create Order', state: '/orders/new', type: 'link' },
                { icon: 'i-Receipt', name: 'All Orders', state: '/orders/list', type: 'link' },
                { icon: 'i-Receipt-3', name: 'Quotes', state: '/orders/filtered/1', type: 'link' },
                { icon: 'i-Post-Sign-2-ways', name: 'Sales Orders', state: '/orders/filtered/2', type: 'link' },
                { icon: 'i-Box-Full', name: 'Measured Orders', state: '/orders/filtered/3', type: 'link' },
                { icon: 'i-Cash-register-2', name: 'Purchase Orders', state: '/orders/filtered/4', type: 'link' },
                { icon: 'fal fa-times', name: 'Denied Orders', state: '/orders/filtered/8', type: 'link' },
                { icon: 'fal fa-check', name: 'Approved Orders', state: '/orders/filtered/9', type: 'link' },
                { icon: 'i-Diploma-2', name: 'Installed Orders', state: '/orders/filtered/5', type: 'link' },
                { icon: 'i-Newspaper', name: 'Partially Installed', state: '/orders/filtered/6', type: 'link' },
                { icon: 'fal fa-ban', name: 'Cancelled Orders', state: '/orders/filtered/10', type: 'link' },
                { icon: 'i-Calendar-4', name: 'Complete', state: '/orders/filtered/7', type: 'link' },
                // { icon: 'i-Add', name: 'Create Order', state: '/orders/new', type: 'link' },
            ],
            state: '/orders/list'
        },
        // {   
        //     name: 'Invoices',
        //     type: 'link',
        //     disabled: this.canShow('invoice'),
        //     icon: 'fal fa-file-invoice',
        //     state: '/invoice'
        // },
        {
            name: 'Project',
            disabled: this.canShow('projects'),
            type: 'dropDown',
            icon: 'i-Building',
            sub: [
                { icon: 'i-Building', name: 'Projects', state: '/projects/list', type: 'link' },         
                { icon: 'i-Business-ManWoman', name: 'Developers', state: '/developers/list', type: 'link' },
                { icon: 'i-Business-Mens', name: 'Customers', state: '/customers/list', type: 'link' }     
            ],
            state: '/projects/list'
        },        
        {
            name: 'Settings',
            disabled: this.canShow('doors'),
            type: 'dropDown',
            icon: 'i-Key',
            sub: [
                { icon: 'i-Door', name: 'Door', state: '/doors/list', type: 'link' },
               { icon: 'i-Computer-Secure', name: 'Door Extras', state: '/extra-categories/list', type: 'link' }, 
                //{ icon: 'i-Management', name: 'Suppliers', state: '/suppliers/list', type: 'link' },
                { icon: 'i-Business-Mens', name: 'Employees', state: '/employees/list', type: 'link' },
             //   { icon: 'i-Window', name: 'Door Use Types', state: '/door-types/list', type: 'link' }
            ],
            state: '/doors/list'
        },
        {
            name: 'Installer',
            type: 'dropDown',
            disabled: this.canShow('installations'),
            icon: 'i-File-Clipboard-File--Text',
            sub: [
                { icon: 'i-Home1', name: 'Sales Orders', state: '/installations/sales', type: 'link' },
                { icon: 'i-Home-4', name: 'Measured Orders', state: '/installations/measured', type: 'link' },
                { icon: 'i-University1', name: 'Purchase Orders', state: '/installations/purchase', type: 'link' },
                { icon: 'fal fa-check', name: 'Approved Orders', state: '/installations/approved', type: 'link' },
                { icon: 'i-University', name: 'Partially Installed', state: '/installations/partially', type: 'link' }
            ],
            state: '/installations/sales'
        },
        {
            name: 'Reports',
            disabled: this.canShow('reports'),
            type: 'dropDown',
            icon: 'i-ID-3',
            sub: [
                { icon: 'i-Receipt-4', name: 'Works', state: '/reports/list', type: 'link' }
            ],
            state: '/reports/list'
        },
        // {
        //     name: 'Customers',
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        //     type: 'dropDown',
        //     icon: 'i-MaleFemale',
        //     sub: [
        //         { icon: 'i-MaleFemale', name: 'Customers', state: '/customers/list', type: 'link' },
        //         { icon: 'i-Add', name: 'Customer', state: '/customers/new', type: 'link' },
              
        //     ]
        // },
       
    ];

    canShow(route:string):boolean{
        const userRoleId: number = this.authService.getUserRole();
        const userRole = this.rolesService.getAccess(userRoleId);
        //console.log(route+" "+userRole.routeAccess[route]);
        if (userRole) {
        if (userRole.routeAccess[route]) {
            //console.log(userRole.routeAccess[route]);
            return true;
        }
        }
    }

    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    // You can customize this method to supply different menu for
    // different user type.
    // publishNavigationChange(menuType: string) {
    //   switch (userType) {
    //     case 'admin':
    //       this.menuItems.next(this.adminMenu);
    //       break;
    //     case 'user':
    //       this.menuItems.next(this.userMenu);
    //       break;
    //     default:
    //       this.menuItems.next(this.defaultMenu);
    //   }
    // }
}
