import { AvailableDoorExtra } from "./available-door-extra.model";
import { Supplier } from "./supplier.model";

export class Door {
	id?: string;
	title?: string;
	use_type?: string;
	available_extras?: AvailableDoorExtra[];
	type?: string;
	image_url?: string;
	reference: string;
	sale_price: number;
	cost_price: number;
	min_height: number;
	max_height: number;
	min_width: number;
	max_width: number;
	installation_fee: number;
	extras?: number[];
	files?: any[];
	supplier?: Supplier;
	is_inside?:boolean;
	is_outside?: boolean;
	allow_two_face?: boolean;
  
	constructor(input?) {
	  input = input || {};
	  this.id = input.id || '';
	  this.title = input.title || '';
	  this.use_type = input.use_type || '';
	  this.available_extras = input.available_extras || [];
	  this.type = input.type || '';
	  this.image_url = input.image_url || '';
	  this.reference = input.reference || '';
	  this.sale_price = input.sale_price || '';
	  this.cost_price = input.cost_price || '';
	  this.min_height = input.min_height || '';
	  this.max_height = input.max_height || '';
	  this.min_width = input.min_width || '';
	  this.max_width = input.max_width || '';
	  this.installation_fee = input.installation_fee || '';
	  this.supplier = input.supplier || {};
	  this.files = input.files || {};
	  this.is_inside = input.is_inside || false;
	  this.is_outside = input.is_outside || false;
	  this.allow_two_face = input.allow_two_face || false;
	}

	hasExtra(id) 
	{
		let found = false;
		this.available_extras.forEach(function(e) {
			if(found)
			{
				return;
			}
			e.extras.forEach(element => {
				if(element.id == id)
				{
					found = true;
					return;
				}	
			});
			
		})

		return found;
	}
  }
  