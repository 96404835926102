import { DoorExtra } from "./door-extra.model";

export class AvailableDoorExtra {
    id?: string;
    title: string;
    extras?: DoorExtra[];

    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.title = input.title || '';
        this.extras = input.extras || [];
      }
}