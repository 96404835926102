import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { Router } from '@angular/router';
import { EmployeeService } from './shared/services/employee.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'bootDash';

  constructor(
    private auth : AuthService,
    private router : Router,
    private authService : AuthService,
    private userService : EmployeeService){}

  ngOnInit(){
    console.log(this.auth.getToken());
    if(!this.auth.authenticated){
      console.log("Navigating!");
      this.router.navigate(['sessions/signin']);
    }
    this.authService.getUser().subscribe(res => {
      this.userService.getEmployee(res['id']).subscribe(res => {

      },
      err => {
        this.authService.signout();
      });
    },
    err => {
      console.log(err);
    });
  }

}
