import { Component, OnInit, HostListener } from "@angular/core";
import {
  NavigationService,
  IMenuItem,
  IChildItem
} from "../../../../services/navigation.service";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { filter } from "rxjs/operators";
import { Utils } from "../../../../utils";
import { OrderService } from "src/app/shared/services/order.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { EmployeeService } from "src/app/shared/services/employee.service";
import { RoleGuard } from "src/app/shared/services/role.guard";
import { RolesService } from "src/app/shared/services/roles.service";

@Component({
  selector: "app-sidebar-compact",
  templateUrl: "./sidebar-compact.component.html",
  styleUrls: ["./sidebar-compact.component.scss"]
})
export class SidebarCompactComponent implements OnInit {

  
  constructor(
    public router: Router,
     public navService: NavigationService,
     private orderService : OrderService,
     private roleService : RolesService,
     private authService : AuthService) {}


  getService() : NavigationService{
    return this.navService;
  }

  getLogo(){
    return this.authService.getLogo();
  }

  getNav(){
    let nav:IMenuItem[] = this.navService.nav;
    let formattedNav:IMenuItem[] = [];
    for(let navigation of nav){
      navigation.disabled = !this.canShow(navigation.state.split('/')[1]);
      //console.log(navigation.state.split('/')[1]+' '+this.canShow(navigation.state.split('/')[1]));
      if(!navigation.disabled){
        formattedNav.push(navigation);
      }
    }
    return formattedNav;
  }

  canShow(route:string):boolean{
    const userRoleId: number = this.authService.getUserRole();
    const userRole = this.roleService.getAccess(userRoleId);
    //console.log(route+" "+userRole.routeAccess[route]);
    if (userRole) {
    if (userRole.routeAccess[route]) {
        //console.log(userRole.routeAccess[route]);
        return true;
    }
    }
    return false;
  }

  setDefaultItem(){
    if(localStorage.getItem("menuItem")){
      this.navService.lastMenu = JSON.parse(localStorage.getItem("menuItem"));
    }
    if(this.navService.lastMenu){
      //console.log("TRIGGERED!");
      this.navService.selectedItem = this.navService.lastMenu;
      this.setActiveMainItem(this.navService.lastMenu);
    }
  }

  ngOnInit() {
    this.updateSidebar();
    let _this = this;
    if(!this.navService.lastMenu){
      this.navService.lastMenu = this.navService.selectedItem;
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart){
        if(this.navService.confirmBeforeChange){
          console.log("Sidebar Clicked");
          // if(!confirm("Are you sure?")){
          //   return;
          // }
          // else{
          //   this.navService.confirmBeforeChange = false;
          // }	
        }
      }
    });
    // CLOSE SIDENAV ON ROUTE CHANGE
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(routeChange => {
        this.closeChildNav();
        if (Utils.isMobile()) {
          this.navService.sidebarState.sidenavOpen = false;
        }
      });

    this.navService.menuItems$.subscribe(items => {
      this.navService.nav = items;
      this.setActiveFlag();
    });
  }

  selectItem(item) {
    this.navService.sidebarState.childnavOpen = true;
    this.navService.selectedItem = item;
    this.setActiveMainItem(item);
  }
  closeChildNav() {
    this.navService.sidebarState.childnavOpen = false;
    this.setActiveFlag();
  }

  onClickChangeActiveFlag(item) {
    console.log("UPDATING: ", item);
    this.orderService.viewMode = "list";
    localStorage.setItem("menuItem",JSON.stringify(item));
    this.navService.lastMenu = item;
    this.setActiveMainItem(item);
  }
  setActiveMainItem(item) {
    this.navService.nav.forEach(item => {
      item.active = false;
    });
    item.active = true;
  }

  setActiveFlag() {
    // this.orderService.viewMode = "list";
    if (window && window.location) {
      const activeRoute = window.location.hash || window.location.pathname;
      this.navService.nav.forEach(item => {
        item.active = false;
        if (activeRoute.indexOf(item.state) !== -1) {
          this.navService.selectedItem = item;
          item.active = true;
          localStorage.setItem("menuItem",JSON.stringify(item));
        }
        if (item.sub) {
          item.sub.forEach(subItem => {
            subItem.active = false;
            if (activeRoute == '#' + (subItem.state)) {
              this.navService.selectedItem = item;
              item.active = true;
              localStorage.setItem("menuItem",JSON.stringify(item));
              // subItem.active = true;
              // debugger;
            }
            if (subItem.sub) {
              subItem.sub.forEach(subChildItem => {
                if (activeRoute.indexOf(subChildItem.state) !== -1) {
                  this.navService.selectedItem = item;
                  item.active = true;
                  localStorage.setItem("menuItem",JSON.stringify(item));
                  subItem.active = true;
                }
              });
            }
          });
        }
      });
    }
  }

  updateSidebar() {
    if (Utils.isMobile()) {
      this.navService.sidebarState.sidenavOpen = false;
      this.navService.sidebarState.childnavOpen = false;
    } else {
      this.navService.sidebarState.sidenavOpen = true;
    }
  }
  toggelSidebar() {
    const state = this.navService.sidebarState;
    state.sidenavOpen = !state.sidenavOpen;
    state.childnavOpen = !state.childnavOpen;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.updateSidebar();
  }
}
