import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Developer } from '../models/developer.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApartmentService {
  
  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }

  apartments = [];

  create(project, developer) {
    return this.http.post<Developer>(`${environment.base_url}v1/project/${project}/apartment`, developer);
  }

  copy(project,customer,reference) {
    return this.http.post<Developer>(`${environment.base_url}v1/project/${project}/apartment`,{
      "reference": reference,
      "project": project,
      "customer" : customer
    });
  }

  update(project, id, developer) {
    return this.http.put<Developer>(`${environment.base_url}v1/project/${project}/apartment/${id}`, developer);
  }

  deleteApartment(projectId,id){
    return this.http.delete(`${environment.base_url}v1/project/${projectId}/apartment/${id}`);
  }

  updateCustomer(projectId, apartmentId, customerId, reference) {
    return this.http.put(`${environment.base_url}v1/project/` + projectId + "/apartment/" + apartmentId, {
      "reference": reference,
      "project": projectId,
      "customer": customerId
    })
  }

  newDoor(aptId,projectId,door){
    //console.log("This: ",door);
          return this.http.post(`${environment.base_url}v1/project/` + projectId + "/door", {
            "reference" : door['reference'],
            "project" : projectId,
            "apartment" : aptId,
            "is_customer_configured": door['is_customer_configured'] ? door['is_customer_configured'] : false,
            "door_use_type" : door['use_type'],     
            "door_number": door['door_number'] 
        })
  }

  deleteOpening(projectId,openingId){
    return this.http.delete(`${environment.base_url}v1/project/` + projectId + "/door/"+openingId);
  }

  saveDoor(aptId, projectId,door){
    //console.log("This: ",door);
      return this.http.put(`${environment.base_url}v1/project/` + projectId + "/door/"+door.id, {
        "id": door.id,
        "reference" : door['reference'],
        "project" : projectId,
        "apartment" : aptId,
        "length" : +door['length'],
        "height" : +door['height'],
        "width" : +door['width'],
        "opening": door['opening'],
        "door_use_type" : door['use_type'] ,
        "door_number": door['door_number'] 
    })
  }

  newApartment(projectId, customerId, reference) {
    let obj : any = ({
       "reference": reference,
      "project": projectId,
      "customer": customerId
    });
    let objNoCustomer : any = ({
      "reference": reference,
     "project": projectId
   });
    return this.http.post(`${environment.base_url}v1/project/` + projectId + "/apartment", (customerId >= 0 ? obj : objNoCustomer));
  }

  saveApartment(projectId, customerId, reference, aptId) {
    let obj : any = ({
      "reference": reference,
     "project": projectId,
     "customer": customerId
   });
   let objNoCustomer : any = ({
     "reference": reference,
    "project": projectId
  });
    return this.http.put(`${environment.base_url}v1/project/` + projectId + "/apartment/"+aptId,  (customerId >= 0 ? obj : objNoCustomer))
  }

  getById(project, id) {
    return this.http.get<Developer>(`${environment.base_url}v1/project/${project}/apartment/${id}`);
  }

  getAll(project) {
    return this.http.get(`${environment.base_url}v1/project/${project}/apartment`)
      .pipe(
        map(responseData => {
          //console.log(responseData);
          const postsArray: Developer[] = [];

          for (const key in responseData['data']) {
            if (responseData['data'].hasOwnProperty(key)) {
              postsArray.push({ ...responseData['data'][key], web_id: key });
              //console.log(responseData['data'][key]);
            }
          }
          return postsArray;

        }))
  }
}
