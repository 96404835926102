import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  
  constructor(
    private http : HttpClient,
    private auth : AuthService
    ) { }

    getSystemRoles(){
      return this.http.get(`${environment.base_url}v1/systemroles`);
    }

    getAllEmployees(searchParams?){
      return this.http.get(`${environment.base_url}v1/user`,{params:searchParams});
    }

    getEmployee(id){
      return this.http.get(`${environment.base_url}v1/user/`+id);
    }

    deleteEmployee(id){
      return this.http.delete(`${environment.base_url}v1/user/`+id);
    }

    createEmployee(form : FormGroup){
      return this.http.post(`${environment.base_url}v1/user`,form.getRawValue());
    }

    editEmployee(form : FormGroup, id){
      return this.http.put(`${environment.base_url}v1/user/`+id,form.getRawValue());
    }

    updateCredentials(userForm){
      return this.http.put(`${environment.base_url}v1/user/settings`,userForm.getRawValue());
    }

    getCredentials(){
      return this.http.get(`${environment.base_url}v1/user/settings`);
    }


}
