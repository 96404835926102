import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from '../utils';
import { pipe } from 'rxjs';
import { subscribeOn } from 'rxjs/operators';

export interface test{
        api_key: string;
        expires_at: Date;
        refresh_token: string;
}

@Injectable({
    providedIn: 'root'
    
})
export class DataLayerService {

    token: string;
    


    constructor(
        private http: HttpClient
    ) { }


        login(){
            this.http.post<test[]>('https://api.bilom.freshmenstudio.com/api/v1/login',
            {
                email:"christian@freshmen.studio",
                password:'password'
            })
            
        }

    getInvoices() {
        return this.http.get<any[]>('https://api.bilom.freshmenstudio.com/api/v1/customer')
            
    }
    getInvoice(id) {
        return this.http.get<any[]>('/api/invoices/'+id);
    }
    saveInvoice(invoice) {
        if(invoice.id) {
            return this.http.put<any[]>('/api/invoices/'+invoice.id, invoice);
        } else {
            invoice.id = Utils.genId();
            return this.http.post<any[]>('/api/invoices/', invoice);
        }
    }
    deleteInvoice(id) {
        return this.http.delete<any[]>('/api/invoices/'+id);
    }
    getMails() {
        return this.http.get<any[]>('/api/mails');
    }
    getCountries() {
        return this.http.get<any[]>('/api/countries');
    }
    getProducts() {
        return this.http.get<any[]>('api/products');
    }
}
